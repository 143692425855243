import { createSlice } from '@reduxjs/toolkit'

const rechangeModal = createSlice({
  // 模块名称独一无二
  name: 'rechangeModal',
  // 初始数据
  initialState: {
    show: false
  },
  // 修改数据的同步方法
  reducers: {
    show (state, action) {

      state.show = !state.show
    }
  }
})

const { show } = rechangeModal.actions
const reducer = rechangeModal.reducer

// 导出修改数据的函数
export { show }
// 导出reducer
export default reducer