import { createSlice } from '@reduxjs/toolkit'

const sign = createSlice({
  // 模块名称独一无二
  name: 'drawer',
  // 初始数据
  initialState: {
    show: false,
    cardType: 0
  },
  // 修改数据的同步方法
  reducers: {
    show (state, action) {

      state.show = !state.show
    },
    open (state, action) {
      console.log(action)
      if (action.payload == 0) {
        state.cardType = 0
      } else if (action.payload == 1) {
        state.cardType = 1
      } else if (action.payload == 2) {
        state.cardType = 2
      }
      state.show = true
    },
    close (state, action) {
      state.show = false
    }
  }
})

const { show, open, close } = sign.actions
const reducer = sign.reducer

// 导出修改数据的函数
export { show, open, close }
// 导出reducer
export default reducer