import { createSlice } from '@reduxjs/toolkit'

const bindCfAddressModal = createSlice({
  // 模块名称独一无二
  name: 'bindCfAddressModal',
  // 初始数据
  initialState: {
    show: false,
    // code: ''
  },
  // 修改数据的同步方法
  reducers: {
    show (state, action) {
      console.log('bindInviteModal action', action.payload)
      // if (action.payload) {
      //   state.show = !state.show
      //   state.code = action.payload
      // } else {
      //   state.show = !state.show
      //   state.code = ''
      // }
      if (action.payload === 'open') {
        state.show = true
        // state.code = action.payload
      } else if (action.payload === 'close') {
        state.show = false
        // state.code = ''
      }

    }
  }
})

const { show } = bindCfAddressModal.actions
const reducer = bindCfAddressModal.reducer

// 导出修改数据的函数
export { show }
// 导出reducer
export default reducer