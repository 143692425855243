import { createSlice } from '@reduxjs/toolkit'

const left = createSlice({
  // 模块名称独一无二
  name: 'left',
  // 初始数据
  initialState: {
    show: false
  },
  // 修改数据的同步方法
  reducers: {
    show (state,action) {
       
      state.show = !state.show
    }
  }
})

const { show } = left.actions
const reducer = left.reducer

// 导出修改数据的函数
export { show }
// 导出reducer
export default reducer