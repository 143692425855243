import { createSlice } from '@reduxjs/toolkit'
import { getLang, setLang } from '../../utils/token'
const langChange = createSlice({
  // 模块名称独一无二
  name: 'langChange',
  // 初始数据
  initialState: {
    lang: getLang() || 'zh_CN'
  },
  // 修改数据的同步方法
  reducers: {
    // clearSign (state, action) {
    //   console.log('clearSign', action)
    //   state.signInfo = {}
    // },
    setNewLang (state, action) {
      console.log('setNewLang', action.payload)
      // setLang(action.payload)
      state.lang = action.payload

    }
  }
})

const { setNewLang } = langChange.actions
const reducer = langChange.reducer

// 导出修改数据的函数
export { setNewLang }
// 导出reducer
export default reducer