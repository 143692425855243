import { createSlice } from '@reduxjs/toolkit'

const socializeConfig = createSlice({
  // 模块名称独一无二
  name: 'socializeConfig',
  // 初始数据
  initialState: {
    info: {}
  },
  // 修改数据的同步方法
  reducers: {
    SaveInfo (state, action) {
      // console.log(state, action)
      state.info = action.payload
      // console.log(state.info)
      // state.Info = state.Info
    }
  }
})

const { SaveInfo } = socializeConfig.actions
const reducer = socializeConfig.reducer

// 导出修改数据的函数
export { SaveInfo }
// 导出reducer
export default reducer