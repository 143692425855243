import { createSlice } from '@reduxjs/toolkit'

const langModal = createSlice({
  // 模块名称独一无二
  name: 'langModal',
  // 初始数据
  initialState: {
    show: false
  },
  // 修改数据的同步方法
  reducers: {
    show (state, action) {

      state.show = !state.show
    },
    showClose (state, action) {
      state.show = false
    }
  }
})

const { show, showClose } = langModal.actions
const reducer = langModal.reducer

// 导出修改数据的函数
export { show, showClose }
// 导出reducer
export default reducer