import { createSlice } from '@reduxjs/toolkit'

const sign = createSlice({
  // 模块名称独一无二
  name: 'sign',
  // 初始数据
  initialState: {
    show: false,
    signInfo: {}
  },
  // 修改数据的同步方法
  reducers: {
    show (state, action) {

      state.show = !state.show
    },
    clearSign (state, action) {
      console.log('clearSign', action)
      state.signInfo = {}
    },
    setNewSign (state, action) {
      console.log('setNewSign', action.payload)
      state.signInfo = action.payload
    }
  }
})

const { show, clearSign, setNewSign } = sign.actions
const reducer = sign.reducer

// 导出修改数据的函数
export { show, clearSign, setNewSign }
// 导出reducer
export default reducer