

const layout = () => {
    if (window.plus) {
        window.plus.wobj.loadURL('/')
        // window.plus.wobj.reload(true);
    } else {
        window.location.href('/')
    }
}
export { layout }